import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import './index.css'

const pinia = createPinia();
const app = createApp(App);

app.config.globalProperties.API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

app.use(pinia);
app.use(router);
app.use(VueAxios, axios);
app.provide('axios', app.config.globalProperties.axios);
app.mount('#app');
