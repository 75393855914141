import { useCookies } from "vue3-cookies";
import axios from "axios";
import CommonUtil from "./common-util";

const { cookies } = useCookies();

export default class Auth {
    public static SaveRefreshTokenToCookie(refToken: string) {
        cookies.set("refresh_token", refToken, "90d");
    }

    public static SaveAccessTokenToCookie(accToken: string) {
        cookies.set("access_token", accToken, "24h");
    }

    public static async RefreshAccessToken() {
        // 액세스 토큰이 있으면 액세스토큰을 갱신한다.
        if (cookies.get("access_token")) {
            const API_BASE_URL = CommonUtil.getApiBaseUrl();
            const url = `${API_BASE_URL}/api/analysis_gate/make_new_access_token/`
            axios.defaults.headers.common['Authorization'] = `Bearer ${cookies.get("access_token")}`;
            const ret = await axios.get(url);
            const accToken = ret.data.access_token.toString();
            // cookie set 5 seconds
            cookies.set("access_token", accToken, "1h");
            Auth.SetupAccessToken();
        }
    }

    public static SetupAccessToken() {
        const accToken = cookies.get("access_token");
        if (accToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${accToken}`;
        }
    }

    public static GetAccessToken() {
        return cookies.get("access_token");
    }

    public static RemoveAccessToken() {
        cookies.remove("access_token");
    }

    public static RemoveRefreshToken() {
        cookies.remove("refresh_token");
    }
}