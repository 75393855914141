import axios from "axios";
import { createWebHistory, createRouter } from "vue-router";
import Auth from "./utils/auth";

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "BoksuYega", params: { bidid: "230710201423879-01-00-01" }, query: { bidtype: "con" } },
  },
  {
    path: "/relay/:bidid/",
    name: "TokenRelay",
    redirect: { name: "BoksuYega" },
  },
  {
    path: "/bsyg/:bidid/",
    name: "BoksuYega",
    component: () => import("./views/BoksuYega.vue"),
  },
  {
    path: "/shbs/:bidid/",
    name: "SunghyangBunsuk",
    component: () => import("./views/SunghyangBunsuk.vue"),
  },
  {
    path: "/bdbsg/:bidid/",
    name: "BindoBunsukGraph",
    component: () => import("./views/BindoBunsukGraph.vue"),
  },
  {
    path: "/bdbsc/:bidid/",
    name: "BindoBunsukChart",
    component: () => import("./views/BindoBunsukChart.vue"),
  },
  {
    path: "/succomlist/:bidid/",
    name: "SuccomList",
    component: () => import("./views/SuccomList.vue"),
  },
  {
    path: "/combi1365/:bidid/",
    name: "Combi1365View",
    component: () => import("./views/Combi1365View.vue"),
  },
  {
    path: "/dongga/:bidid/",
    name: "DonggaView",
    component: () => import("./views/DonggaView.vue"),
  },
  {
    path: "/scbindo/",
    name: "SuccomBindoView",
    component: () => import("./views/SuccomBindoView.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 라우터 이동 전에 실행된다. (jwt 인증을 위해 설정)
router.beforeEach(async (to, from, next) => {
  if (to.redirectedFrom && to.redirectedFrom?.query?.token) {
    Auth.SaveAccessTokenToCookie(to.redirectedFrom.query.token.toString()); // 리프레시토큰을 쿠키에 저장한다.
    delete to.redirectedFrom.query.token; // 쿼리에서 토큰을 삭제한다.
    delete to.query.token; // 쿼리에서 토큰을 삭제한다.
    next(to); // 토큰을 삭제한 쿼리로 리다이렉트한다.
    return;
  }

  // 쿠키에 있는 액세스토큰을 axios 헤더에 설정한다. (jwt 인증을 위해 설정, 페이지 이동시마다)
  Auth.SetupAccessToken();
  next(); // 다음 페이지로 이동한다.
});

router.afterEach((to, from) => {
  // title 설정
  from;
  if (to?.meta?.title) {
    document.title = to.meta.title as string;
  } else {
    document.title = "BMS 통합분석";
  }
});

// axios 요청 전에 실행된다. (jwt 인증을 위해 설정)
axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const statusCode = error.response?.status;
    if (statusCode && statusCode === 401) { // 401이면 토큰이 만료된 것이므로 쿠키를 삭제하고 다시 접속하도록 한다.
      Auth.RemoveAccessToken();
      Auth.RemoveRefreshToken();
      alert("[401] 사용시간이 만료되어, 요청을 처리할 수 없습니다. 다시 접속해주세요.");
    } else if (statusCode === 422) { // 요청 결과가 422이면 토큰이 만료된 것이므로 쿠키를 삭제하고 다시 접속하도록 한다.
      Auth.RemoveAccessToken();
      Auth.RemoveRefreshToken();
      alert("[422] 요청을 처리할 수 없습니다. 다시 접속해주세요.");
    }
    return error;
  }
);

export default router;